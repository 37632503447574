import {inject} from '@angular/core'
import {first, map, Observable} from 'rxjs'
import {filter} from 'rxjs/operators'
import {ConfigService} from '../services/config.service'

export const authGuard = (): Observable<boolean | Promise<boolean>> => {
  const configService = inject(ConfigService)

  return configService.configState$.pipe(
    filter(Boolean),
    first(),
    map((config) => {
      return config.isReady
    })
  )
}
